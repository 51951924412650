.Navigation {
  padding: 0.5em 0em;
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 999;
}

.Navigation .container {
  max-width: var(--container);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  flex-wrap: wrap;
  padding-left: var(--sidePaddingSmall);
  padding-right: var(--sidePaddingSmall);
}

.Navigation h1 {
  font-family: "Barlow", sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  color: var(--darkBlue);
  font-size: 2rem;
  letter-spacing: 5px;
}

.Navigation ul {
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.Navigation li {
  min-width: 130px;
  text-align: center;
}

.Navigation a {
  text-decoration: none;
  font-family: "Barlow", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  color: var(--darkBlue);
  padding: 0.5em 1em;
  cursor: pointer;
}

.Navigation a:hover {
  font-weight: 700;
}

.Navigation a.active {
  font-weight: 700;
}

@media screen and (max-width: 760px) {
  .Navigation {
    display: none;
  }

  .Navigation .container {
    padding: 0;
    width: 100%;
  }

  .Navigation h1 {
    font-size: 1rem;
    width: 100%;

    padding-bottom: 0.5rem;
  }

  .Navigation ul {
    border-top: 1px solid var(--lightBg);
    width: 100%;
  }

  .Navigation li {
    width: 100%;
    padding: 0.5em 0em;
  }
}
