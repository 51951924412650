.DevPortfolio {
  width: 100%;
  text-align: left;
}

.DevPortfolioIntro {
  margin-bottom: 2rem;
}

.DevPortfolioSectionTitle {
  display: flex;
}

.icon {
  font-size: 2rem;
  margin: 0 0.25em;
  background-color: var(--mediumBg);
  border-radius: 5px;
  padding: 0.25rem;
}

.DevPortfolio ul {
  list-style: none;
  margin-bottom: 2rem;
}
