.project {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 0.75rem;
  padding: 0.5em 1em;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: var(--mediumBg);
}

.projectBody {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.25em;
  flex-wrap: wrap;
}

.projectBody h4 {
  margin-right: 1rem;
  margin-bottom: 0.5rem;
}

.projectBody a {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.1em 1em;
  text-align: center;
  vertical-align: middle;
  font-size: 0.8rem;
  text-transform: uppercase;
  margin: 0 0.25em;
  background-color: var(--blue);
  color: #fff;
  border-radius: 5px;
}

.icons {
  display: flex;
}

.icon {
  margin: 0em 0.25em;
}
