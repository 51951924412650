.About {
  display: flex;
  width: 100%;
  margin-bottom: 2rem;
  flex-wrap: wrap;
}

.secondaryImg {
  width: 100%;
  max-width: 335px;
  /*border-bottom-left-radius: 5px;*/
  /*border-bottom-right-radius: 5px;*/
  border-radius: 10px;
  margin-bottom: 0.5rem;
  background-color: var(--mediumBg);
}

.signature {
  width: 100%;
  margin-bottom: 0.5rem;
}

.secondaryStatement {
  font-size: 1.2rem;
  font-style: italic;
  text-align: left;
  font-weight: 600;
  color: var(--darkBlue);
}

.primaryHeadline {
  font-size: 1.5rem;
  text-align: left;
  font-weight: 600;
  color: var(--darkBlue);
  margin-bottom: 1rem;
}

.topSkills {
  text-align: left;
  width: 80%;
  margin: 0 auto;
  margin-bottom: 1rem;
  line-height: 1.8rem;
  color: var(--darkBlue);
}
.primaryCurrentlyInterested {
  text-align: left;
  font-weight: 600;
  color: var(--darkBlue);
  margin-bottom: 1rem;
  font-size: 1.2rem;
}

.primaryContactIcons {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.primaryContactIcons a {
  display: flex;
  flex-direction: column;
  width: 120px;
  height: 110px;
  justify-content: center;
  align-items: center;
  padding: 0.25em 0.5em;
  border: 3px solid var(--darkBlue);
  margin: 0.25rem;
  border-radius: 10px;
  font-size: 0.7rem;
  color: var(--darkBlue);
  text-transform: uppercase;
}

.primaryContactIcons a p {
  margin: 0.2em;
}

.primaryContactIcons li {
  display: inline;
}

.primaryIcon {
  font-size: 3rem;
  color: var(--darkBlue);
}

@media screen and (max-width: 675px) {
  .secondaryImg {
    width: 50%;
  }
  .statementSignatureContainer {
    padding: 0 1em;
  }

  .secondaryStatement {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 450px) {
  .secondaryImg {
    width: 100%;
  }
  .statementSignatureContainer {
    padding: 0em;
  }

  .secondaryImg {
    max-width: 435px;
  }
}
