.Section {
  width: 100%;
  min-height: 100vh;
}

.Section .container {
  max-width: var(--container);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0rem auto;
  padding-top: 4rem;
  padding-bottom: 3rem;
  padding-left: var(--sidePaddingSmall);
  padding-right: var(--sidePaddingSmall);
}

.sectionTitle {
  position: relative;
}

.sectionTitle h2 {
  text-transform: uppercase;
  margin-left: 2.5rem;
}

.sectionTitle:before {
  position: absolute;
  top: 0.5rem;
  left: 0;
  content: "";
  width: 1.875rem;
  height: 0.5rem;
  background-color: #1d3f62;
}
