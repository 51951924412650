.SecondaryColumn {
  display: flex;
  flex-direction: column;
  padding: 0 0.5em;
  min-width: 225px;
  width: 35%;
}

.SecondaryColumn p {
  text-align: left;
  margin-bottom: 0.5em;
}

@media screen and (max-width: 675px) {
  .SecondaryColumn {
    flex-direction: row;
    width: 100%;

    justify-content: start;
    align-items: flex-start;
  }
}

@media screen and (max-width: 450px) {
  .SecondaryColumn {
    flex-direction: column;
  }
}
