.ExperienceItem {
  width: 100%;
  text-align: left;
  background-color: var(--mediumBg);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-bottom: 1em;
}

.header {
  display: flex;
  background-color: var(--blue);
  color: #fff;
  padding: 0.5em;
  font-weight: 600;
}

.body {
  display: flex;
  padding: 0.5em 1em;
  flex-direction: column;
}

.body h5 {
  margin-bottom: 0.3em;
}

.body ul {
  padding-left: 1em;
  margin-bottom: 0.75rem;
}

.body li {
  font-size: 0.8em;
}

.body ul a:hover {
  text-decoration: underline;
}

.body p {
  margin-bottom: 0.5em;
}
